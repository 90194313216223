<template>
    <v-card flat light>
      <v-toolbar dark color="primary" flat>
        <v-card-title>Manage Payment Methods</v-card-title>
        <v-spacer/>
        <v-btn 
          icon
          @click="$emit('goBack')"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-list>
          <v-list-item-group>
            <v-list-item
              v-for="(card, index) in cards"
              :key="index"
              @click="updatePaymentMethod(card)"
            >
            <v-row>
              <v-col md="2">
                <v-icon>mdi-set-none</v-icon>
              </v-col>
              <v-col md="5">
                <v-row>
                  <v-list-item-title>{{card.cardType}}</v-list-item-title>
                </v-row>
                <v-row>
                  <v-list-item-subtitle>Exp: {{card.expiry.substr(0,2)}}/{{card.expiry.substr(2,2)}}</v-list-item-subtitle>
                </v-row>
              </v-col>
              <v-spacer/>
              <v-col md="1">
                <v-row>
                  <v-spacer></v-spacer>
                  <v-btn icon>
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-row>
              </v-col>
            </v-row>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-tooltip bottom>
          <template v-slot:activator="{on, attrs}">
            <v-btn
              fab
              dark
              color="primary"
              v-bind="attrs"
              v-on="on"
              @click="createPaymentMethod"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Add payment method</span>
        </v-tooltip>
      </v-card-actions>
      <v-dialog width="unset" v-model="dialog">
        <CardInfo :key="selectedCard.id" :cardToUpdate="selectedCard" :profileid="selectedCard.profileid" :id="selectedCard.id" @closeDialog="dialog = !dialog" v-on="$listeners"/>
      </v-dialog>
    </v-card>
</template>

<script>
import CardInfo from './CardInfo.vue'


const cardTemplate = () => {
  return {
    profileid: 0,
    account: '',
    ccv: '' ,
    expiry: '',
    zipcode: '',
  }
}

export default {
  components: {CardInfo},
  props: ['cards'],
  data () {
    return {
      dialog: false,
      selectedCard: cardTemplate(),
    }
  },
  mounted() {
    console.table(this.cards)
  },
  methods: {
    createPaymentMethod () {
      this.selectedCard = cardTemplate()
      this.dialog = !this.dialog
    },
    updatePaymentMethod (card) {
      this.selectedCard = {...card}
      console.log('card',this.selectedCard)
      this.dialog = !this.dialog
    },
  }
}
</script>

<style scoped>
.v-text-field{
  margin: 5px;
}
</style>