<template>  
  <v-container>
    <v-dialog
      v-model="dialog"
      width="50%"
    >
      <v-card light clas="justify-center" rounded raised>
        <!-- TODO, switch inputs to the custom input components made in ticket #6894 -->
        <v-toolbar dark color="primary" flat>
          <v-card-title>Please confirm choice below</v-card-title>
          <v-spacer/>
          <v-btn 
            icon
            @click="close()"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-actions>
          <v-form>
            <v-radio-group
              v-model='disposition'
              mandatory
              column
              @change='changeText()'
            >
              <v-row>
                <v-radio
                  color="red"
                  :key="1"
                  value="all"
                >
                  <template v-slot:label>
                    <div>
                      I/We authorize the lab to discard of 
                      <strong class="red--text text-uppercase">ALL {{tissuetype}}(s)</strong> 
                      associated with
                      <strong class="red--text">ALL FREEZE or RECEIVE DATES</strong>. 
                      I/We understand this decision will result in my/our Inability to use my/our cryopreserved specimens in future reproductive cycles.
                    </div>
                  </template>
                </v-radio>
              </v-row>
              <v-row>
                <h2 class="white--text">----</h2>
              </v-row>
              <v-row>
                <v-radio
                  label=""
                  color="red "
                  :key="2"
                   value="some"
                >
                  <template v-slot:label>
                    <div>
                      I/We wish to discard <strong class="red--text">SOME</strong> but not all of my/our {{tissuetype}}(s).
                    </div>
                  </template>
                </v-radio>
              </v-row>
            </v-radio-group>
          </v-form>
        </v-card-actions>
        <v-card-actions>
            <v-col md="2">
              <v-btn block dark color="primary" @click="close">Cancel</v-btn>
            </v-col>
            <v-col>
              <v-dialog
                v-model="confirmDialog"
                persistent
                max-width="70%"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    Submit
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title class="text-h5">
                    Are you sure?
                  </v-card-title>
                  <v-row>
                    <v-container>
                   <v-card-text>{{this.text}}</v-card-text>
                   </v-container>
                   </v-row>
                   <v-row
                   v-if="disposition === 'all'"
                   >
                     <v-container>
                   <v-radio-group
              v-model='discardOption'
              mandatory
              column
            >
            <v-radio
                  label=""
                  color="red "
                  :key="1"
                   value="1"
                >
                  <template v-slot:label>
                    <div>
                      
                      <h2>Discard Only</h2>
                      I would like Prelude to discard my sperm currently stored by Prelude under the Freeze Date(s) listed above,
                      and I hereby order and direct Prelude and its physicians, employees and agents to destroy all such frozen 
                      sperm stored under such Freeze Date(s) without the use of such sperm for any purpose prior to destruction.
                      I understand as the result of such destruction no offspring will result from the sperm and I will have no 
                      further rights or claims to such sperm. I have freely, voluntarily and willingly made my decision regarding 
                      the disposal of my sperm and release Prelude from all claims of any nature arising from, or relating to, the 
                      sperm or the destruction thereof.

                    </div>
                  </template>
                </v-radio>
                <v-row>
                <h2 class="white--text">----</h2>
              </v-row>
                <v-radio
                  label=""
                  color="red "
                  :key="2"
                   value="2"
                >
                  <template v-slot:label>
                    <div>
                      <h2>Discard After Use For Training Purposes</h2>
                     I would like to donate my discarded sperm to Prelude for laboratory training and quality assurance under the Freeze Date(s) listed
above. I understand that all materials used for this purpose would be de-identified and no materials would be used to establish a
pregnancy. Any material I have donated to Prelude for laboratory training purposes and quality assurance may be used to improve
Prelude’s laboratory techniques and Prelude’s embryologists’ skills. I understand that all sperm donated for this purpose which has not
been used within 24 months will be discarded in accordance with Prelude’s policies. This selection will apply to all the freeze dates
selected above. I have freely, voluntarily and willingly made my decision regarding the donation for laboratory training and quality
assurance of my sperm and release Prelude from all claims of any nature arising from, or relating to, the sperm or the use for
laboratory training and quality assurance.
                    </div>
                  </template>
                </v-radio>
                   </v-radio-group>
                   </v-container>
            </v-row>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      text
                      @click="confirmDialog = false"
                    >
                      Close
                    </v-btn>
                    <v-btn
                      v-if="this.disposition == 'all'"
                      color="red darken-1"
                      :loading="loading"
                      text
                      @click="submitDisposition()"
                    >
                      Yes, Submit
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <DispositionPdfEmbryos 
      v-if="tissuetype === 'Embryo' && this.documentInfo.patientName && searchedForPartner" 
      :documentInfo="documentInfo" 
      @add-doc="(newDoc) => (doc = newDoc)"
    />
    <DispositionPdfSperm 
      v-if="tissuetype === 'Sperm' && this.documentInfo.patientName && searchedForPartner" 
      :documentInfo="documentInfo" 
      @add-doc="(newDoc) => (doc = newDoc)"
    />
    <DispositionPdfEggs 
      v-if="tissuetype === 'Egg' && this.documentInfo.patientName && searchedForPartner" 
      :documentInfo="documentInfo" 
      @add-doc="(newDoc) => (doc = newDoc)"
    />
  </v-container>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import {auth} from '../../sharedPlugin'
import DispositionPdfEmbryos from './dispositions/DispositionPdfEmbryos.vue'
import DispositionPdfSperm from './dispositions/DispositionPdfSperm.vue'
import DispositionPdfEggs from './dispositions/DispositionPdfEggs.vue'

export default {
  emits: ['goBack'],
  props: ['tissuetype', 'url'],
  components: {
    DispositionPdfEmbryos,
    DispositionPdfSperm,
    DispositionPdfEggs
  },
  computed: {
    ...auth.mapComputed(['user'])
  },
  data () {
    return {
      doc: null,
      disposition: 'all',
      discardOption: 1,
      decision: '',
      type: '',
      dialog: false,
      text: 'After signing consents via docusign, this will result in the discard fo your tissues. Please select one:',
      loading: false,
      envelopeId: null,
      confirmDialog: false,
      documentInfo: {
        patientId: '',
        patientName: '',
        patientEmail: '',
        dob: '',
        address: '',
        emr: '',
        lab: '',
        freezeDates: [],
        partner: {
          firstname: '',
          lastname: '',
          name: '',
          dob: '',
          email: '',
          address: '',
        },
      },
      searchedForPartner: false
    }
  },
  async mounted() {
    await this.getPatientInfo()
    await this.getPartnerInfo()
  },
  methods: {
    async changeText(){
      //alert(this.disposition)
      switch(this.disposition){
      case 'some':
        this.text = 'If you would like to discard only some of your tissue, please contact your center at (615)550-7353.'
        break;
      case 'all':
        this.text = 'After signing consents via docusign, this will result in the discard fo your tissues. Please select one:'
        break;
      default:
        this.text = 'If you would like to discard only some of your tissue, please contact your center at (615)550-7353.'
        break;
      }
    },
    async getPatientInfo() {
      await axios
        .post(`${this.url}/api/v1/inova/patients/getConnectPatient`, {
          connectId: this.user.userId
        }, {
          headers: {
            'x-api-key': '58b54801044df5a0536c25db11c653c743761b2db21e044aedb9f91caa6138a5'
          },
        })
        .then((response) => {
          const { id, firstname, lastname, email, dob, ehrid, address1, clientname } = response.data[0]

          this.documentInfo.patientId = id
          this.documentInfo.patientName = `${firstname} ${lastname}`
          this.documentInfo.patientEmail = email
          this.documentInfo.lab = `${clientname}`
          this.documentInfo.emr = `${ehrid}`
          this.documentInfo.dob = new Intl.DateTimeFormat('en-US').format(new Date(dob))
          this.documentInfo.address = address1
          this.documentInfo.emr = ehrid.toString()
          this.documentInfo.freezeDates.push('All freeze dates and/or recieve dates prior to ' + moment().format('MM/DD/YYYY'))
        })
        .catch((err) => {
          console.error(err)
        })
    },
    async getPartnerInfo() {
      await axios
        .post(`${this.url}/api/v1/inova/patients/getPartners`, {
          id: this.documentInfo.patientId
        }, {
          headers: {
            'x-api-key': '58b54801044df5a0536c25db11c653c743761b2db21e044aedb9f91caa6138a5'
          },
        })
        .then((response) => {
          const { firstname, lastname, email, dob, address1 } = response.data[0]

          this.documentInfo.partner.firstname = firstname
          this.documentInfo.partner.lastname = lastname
          this.documentInfo.partner.name = `${firstname} ${lastname}`
          this.documentInfo.partner.email = email
          this.documentInfo.partner.dob = new Intl.DateTimeFormat('en-US').format(new Date(dob))
          this.documentInfo.partner.address = address1   
          
          this.searchedForPartner = true
        })
        .catch((err) => {
          console.error(err)
        })
    },
    async sendEnvelope() {
      var tab = ''
      switch(this.tissuetype){
      case 'Sperm':
        tab = '0';
        break;
      case 'Embryo':
        tab = '1';
        break;
      case 'Egg':
        tab = '2';
        break;
      }

      await axios
        .post(`${this.url}/api/v1/inova/docusign`, {
          document: this.doc.output('datauristring').split('base64,')[1],
          emailSubject: 'Disposition Request',
          signerName: this.documentInfo.patientName,
          signerEmail: this.documentInfo.patientEmail,
          tab: tab,
          partners: this.tissuetype === 'Embryo' && this.documentInfo.partner.firstname ? [this.documentInfo.partner] : [],
          discardOption: this.discardOption
        }, {
          headers: {
            'x-api-key': '58b54801044df5a0536c25db11c653c743761b2db21e044aedb9f91caa6138a5'
          },
        })
        .then((response) => {
          this.envelopeId = response.data[0].envelopeId
          //alert(response)
        })
        .catch((err) => {
          console.error(err)
          //alert(err)
        })
    },
    async addDisposition() {
      await axios
        .post(`${this.url}/api/v1/inova/dispositions`, {
          userId: 0,
          pid: this.documentInfo.patientId,
          envelopeId: this.envelopeId,
          documentName: 'Disposition Request',
          status: 'sent',
          type: this.tissuetype,
        }, {
          headers: {
            'x-api-key': '58b54801044df5a0536c25db11c653c743761b2db21e044aedb9f91caa6138a5'
          },
        })
        .catch((err) => {
          console.error(err)
        })
    },
    async addDispositionRequest() {
      const dispositionRequest = {
        type: this.type,
        userId: this.user.userId,
        docType: this.tissuetype
      }

      await axios
        .post(`${this.url}/api/v1/connect/dispositions`, dispositionRequest, {
          headers: {
            'x-api-key': '58b54801044df5a0536c25db11c653c743761b2db21e044aedb9f91caa6138a5'
          }
        })
        .then(() => {
          this.$store.commit('showSnackbar', { message: 'Please check your email to DocuSign your disposition.', color: 'success' })
        })
        .catch(() => {
          this.$store.commit('showSnackbar', { message: 'Error requesting disposition.', color: 'error' })
        })
        .finally(() => {
          this.close()
          this.loading = false
        })
    },
    async submitDisposition() {
      this.loading = true

      if (this.disposition=== 'all') {
        await this.sendEnvelope()
        await this.addDisposition()
      }

      await this.addDispositionRequest()

      this.$emit('disposition-submitted')

      this.loading = false
    },
    show() {
      this.dialog=true
    },
    close() {
      this.dialog = false
      this.confirmDialog = false
    }
  }
}
</script>
