<template>
  <div>
    <v-card style="width: 100%" elevation="0" :loading="loading">
      <v-card-title> Process Payment - Invoice {{ invoice }}</v-card-title>
      <v-card-text>
        <v-card flat>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="amount"
                label="Amount"
                hide-details
                clearable
                :loading="loading"
                prepend-inner-icon="mdi-currency-usd"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="description"
                label="Description"
                hide-details
                clearable
                prepend-inner-icon="mdi-text"
                :loading="loading"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-select
                v-model="card"
                :items="cards"
                clearable
                hide-details
                :loading="loading"
                item-text="text"
                item-value="value"
                label="Card"
                prepend-inner-icon="mdi-credit-card"
              ></v-select>
            </v-col>
          </v-row>
        </v-card>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="px-8 py-6">
        <v-btn 
          icon
          @click="$emit('dialog-close')"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          large
          style="color: #fff; background: #305676"
          @click="processPayment"
        >
          Process Payment
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  props: ['invoice', 'invamount', 'url', 'patient'],
  data() {
    return {
      loading: true,
      amount: '',
      active: true,
      cards: [],
      description: 'Cryo Inventory Payment',
      card: null,
      patientHasProfile: false,
      formatter: new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }),
    }
  },
  async mounted() {
    await this.getProfile()
    this.setInvoice()
  },
  methods: {
    setInvoice() {
      this.amount = this.invamount
      this.description = 'Cryo Inventory Payment - Invoice ' + this.invoice
      this.loading = 'false'
    },
    async getProfile() {
      await axios
        .post(
          `${this.url}/api/v1/inova/cardConnect/getProfile`,
          { pid: this.patient.id },
          {
            headers: {
              'x-api-key': '58b54801044df5a0536c25db11c653c743761b2db21e044aedb9f91caa6138a5'
            },
          }
        )
        .then(async (response) => {
          const patientAccounts = response.data

          if (patientAccounts.length > 0) {
            this.patientHasProfile = true
          }

          for (const patientAccount of patientAccounts) {
            await this.getAccountInfo(patientAccount)
          }
        })
        .catch((err) => {
          console.error(err)
        })
    },
    async getAccountInfo(patientAccount) {
      await axios
        .post(
          `${this.url}/api/v1/connect/cardConnect/getAccountInfo`,
          {
            merchid: '496428948889',
            profile: patientAccount.profileid,
            account: patientAccount.accountid,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'x-api-key': '58b54801044df5a0536c25db11c653c743761b2db21e044aedb9f91caa6138a5'
            },
          }
        )
        .then((response) => {
          const { account } = response.data

          this.cards.unshift({
            text: account,
            value: `${patientAccount.profileid}/${patientAccount.accountid}`,
          })
        })
    },
    async processPayment() {
      this.loading = true

      if (!this.amount) {
        this.throwError('Please enter a valid amount.')
        return
      } else if (!this.description) {
        this.throwError('Please enter a description.')
        return
      }

      this.amount = this.formatter
        .format(this.amount.replace(/[^0-9.]/g, ''))
        .slice(1)
        .replace(/[^0-9.]/g, '')

      await this.processCardPayment()
    },
    async processCardPayment() {
      let config = {
        merchid: '496428948889',
        amount: this.amount,
        receipt: 'Y',
        capture: 'Y',
        tokenize: 'Y',
        ecomind: 'E',
        profile: this.card,
      }

      const { 
        firstname, 
        lastname, 
        address1, 
        address2, 
        city, 
        phone, 
        email 
      } = this.patient

      if (firstname && lastname) {
        config.name = `${firstname} ${lastname}`
      }

      if (address1) {
        config.address = address1
      }

      if (address2) {
        config.address2 = address2
      }

      if (city) {
        config.city = city
      }

      if (phone) {
        config.phone = phone
      }

      if (email) {
        config.email = email
      }

      await axios
        .post(`${this.url}/api/v1/connect/cardConnect/processPayment`, { config }, {
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': '58b54801044df5a0536c25db11c653c743761b2db21e044aedb9f91caa6138a5'
          },
        })
        .then(async (response) => {
          const { acctid, respstat, retref, account, profileid } = response.data

          if (respstat === 'B') {
            this.throwError('Something went wrong. Please try again.')
            return
          } else if (respstat === 'C') {
            this.throwError('This card has been declined. Please try another.')
            await this.addTransactionFailed('card', acctid, retref, account)

            return
          }

          let accttype = await this.getAccttype(profileid, acctid)

          await this.addTransaction('card', acctid, retref, account, accttype)
        })
        .catch((err) => {
          console.error(err)
        })
    },
    async getAccttype(profileid, acctid) {
      let accttype = null

      await axios
        .post(
          `${this.url}/api/v1/connect/cardConnect/getAccountType`, {
            profileid,
            acctid
          }, {
            headers: {
              'Content-Type': 'application/json',
              Authorization:
                'Basic ' +
                btoa('pfcbsllc:$yhJ!P3dfPr!PaTas!nap'),
            },
          }
        )
        .then((response) => {
          accttype = response.data[0]['accttype']
        })

      return accttype
    },
    addTransaction(type, ccaccountid, retref, account, accttype) {
      axios
        .post(
          `${this.url}/api/v1/inova/cardConnect/invoiceTransaction`,
          {
            pid: this.patient.id,
            email: this.patient.email,
            type,
            amount: this.amount,
            ccaccountid,
            retref,
            account,
            accttype,
            description: this.description,
            invoice: this.invoice,
          },
          {
            headers: {
              'x-api-key': '58b54801044df5a0536c25db11c653c743761b2db21e044aedb9f91caa6138a5'
            },
          }
        )
        .then(() => {
          this.loading = false
          this.amount = ''
          this.description = ''
          this.card = null
          this.$store.commit('showSnackbar', { message: 'Payment has been sent! It may take up to 24 hours to post to your account.', color: 'success' })
          this.$router.go(-1)
        })
        .catch((err) => {
          console.error(err)
        })
    },
    async addTransactionFailed(type, ccaccountid, retref, account, accttype) {
      await axios
        .post(
          `${this.url}/api/v1/inova/cardConnect/transaction/failed`,
          {
            pid: this.patient.id,
            email: this.patient.email,
            type,
            amount: this.amount,
            ccaccountid,
            retref,
            account,
            accttype,
            description: this.description,
          },
          {
            headers: {
              'x-api-key': '58b54801044df5a0536c25db11c653c743761b2db21e044aedb9f91caa6138a5'
            },
          }
        )
        .then(() => {
          this.loading = false
          this.amount = ''
          this.description = ''
          this.card = null
        })
        .catch((err) => {
          console.error(err)
        })
    },
    throwError(message) {
      this.loading = false
      this.$store.commit('showSnackbar', { message, color: 'error' })
    },
  },
}
</script>
