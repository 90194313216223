<template>
    <v-card light width="500px" clas="justify-center" rounded raised>
      <!-- TODO, switch inputs to the custom input components made in ticket #6894 -->
      <v-toolbar flat>
        <v-card-title v-if="this.card.profileid != 0">Update Payment Method</v-card-title>
        <v-card-title v-if="this.card.profileid === 0">Save Payment Method</v-card-title>
        <v-spacer/>
        <v-btn 
          icon
          @click="$emit('closeDialog')"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-form
          v-model="valid"
          ref="form"
          lazy-validation
        >
          <v-text-field
            label="Card Number"
            v-model="card.account"
            :rules="[...inputRule, ...accountRule]"
            validate-on-blur
          />
          <CardExpiration v-model="card.expiry" :rules="inputRule" />
          <v-text-field
            label="Card CVV"
            v-model="card.ccv"
            :rules="[...inputRule, ...cvvRule, ...onlyNumsRule]"
            validate-on-blur
          />
          <v-text-field
            label="Zip Code"
            v-model="card.zipcode"
            :rules="[...inputRule, ...onlyNumsRule]"
            validate-on-blur
          />
          <v-row v-if="this.card.profileid != 0">
            <v-col cols ='12'>
            <v-btn block dark color="red" @click="removePaymentMethod"><v-icon>mdi-close</v-icon>Remove Card</v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="6">
              <v-btn block dark color="primary" @click="$emit('closeDialog')">Cancel</v-btn>
            </v-col>
            <v-col md="6">
              <v-btn block dark color="primary" @click="savePaymentMethod">Save</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
  </v-card>
</template>

<script>
import CardExpiration from './CardExpiration.vue';
import axios from 'axios'

export default {
  emits: ['goBack'],
  components: {CardExpiration},
  props: ['profileid', 'id', 'cardToUpdate'],
  data () {
    return {
      valid: false,
      card: {
        profileid: 0,
        account: '',
        ccv: '',
        expiry: '',
        zipcode: '',
        delete: ''
      },

      inputRule: [
        v => !!v || "Input Required",
      ],
      accountRule: [
        v => (v && (v.length == 15 || v.length == 18 || v.length == 19)) || "Card number should be ####-####-####-####"
      ],
      cvvRule: [
        v => (v && (v.length == 3 || v.length == 4)) || "Must be 3 or 4 characters"
      ],
      onlyNumsRule: [
        v => (v && /^\d+$/.test(v)) || "Only numbers allowed"
      ]
    }
  },
  mounted() {
    if (this.cardToUpdate) {
      this.card = this.cardToUpdate;
    }
    console.log('acct', this.card.account)
  },
  methods: {
    async savePaymentMethod () {
      if(this.$refs.form.validate()){
        if(this.profileid && this.id){
          this.card.profileid = this.profileid
          this.card.id = this.id
        }
        
        const card = this.formatCard();
        if(card.profileid !== 0) {
          this.$emit('add-update-card', card, {profile: card.profileid, profileUpdate: "Y"});
          this.$emit('closeDialog');
        }
        else {
          this.$emit('add-update-card', card, {defaultacct: "Y"});
          this.$emit('closeDialog');
        }
      }
      document.getElementById('cardframe').src = document.getElementById('cardframe').src
    },
    formatCard(){
      return { 
        ...this.card, 
        account: this.card.account.replace(/\D/g, '')
      }
    },
    async removePaymentMethod() {
      await axios.post('https://dev-inceptionapi.azurewebsites.net/api/v1/connect/cardConnect/remove', {
        id: this.card.id,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': '58b54801044df5a0536c25db11c653c743761b2db21e044aedb9f91caa6138a5',
        }
      })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.$emit('closeDialog');
          this.$store.commit('showSnackbar', {message: 'Card has been removed.', color: 'green'});
        })
    },
  },
}
</script>

<style scoped>
.v-btn{
  margin: 5px;
}
</style>
