 <template>
 <v-stepper v-model="e1">
    <v-stepper-header>
      <v-stepper-step
        :complete="e1 > 1"
        step="1"
      >
        Enter Card
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step
        :complete="e1 > 2"
        step="2"
      >
        Make Payment
      </v-stepper-step>

      
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content step="1">
        <v-card
          class="mb-12"
          color="grey lighten-1"
          height="290px"
        >
        <CardInfo ref="cardform" :patient="patient" ></CardInfo>
        </v-card>

        <v-btn
          color="primary"
          @click="savePaymentMethod()"
        >
          Continue
        </v-btn>

        <v-btn text @click="$emit('closeDialog')">
          Cancel
        </v-btn>
      </v-stepper-content>

      <v-stepper-content step="2">
        <v-card
          class="mb-12"
          color="grey lighten-1"
         height="175px"
        >
        <CardForm ref="payment" :patient="patient"/>
        </v-card>

       <v-btn @click="process()">
         Process Payment
        </v-btn>

        <v-btn text @click="$emit('closeDialog')">
          Cancel
        </v-btn>
      </v-stepper-content>

      
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import CardInfo from './CardInfo2.vue'
import CardForm from './CardForm2.vue'

export default {
  props: ['patient'],
  components: { CardInfo, CardForm },
  data () {
    return {
      e1: 1,
      bus: null,
    }
  },
  methods: {
    async process(){
      await this.$refs.payment.processPayment()
      this.$emit('closeDialog')
    },
    async savePaymentMethod(){
      await this.$refs.cardform.savePaymentMethod()
      await this.$refs.payment.getProfile()
      this.e1 = 2
    }
  },
}
</script>