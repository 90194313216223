<template>
  <div></div>
</template>
<script>
import { jsPDF } from 'jspdf'
import pdfMixin from '../../../mixins/pdfMixin.js'
import moment from 'moment'

export default {
  props: ['documentInfo'],
  mixins: [pdfMixin],
  mounted() {
    this.doc = new jsPDF()
    this.$emit('add-doc', this.doc)
    this.loadPdf()
  },
  watch: {
    documentInfo() {
      console.log('running')
      this.currentXcoord = 10
      this.currentYcoord = 10

      this.doc = new jsPDF()
      this.$emit('add-doc', this.doc)
      this.loadPdf()
    },
  },
  methods: {
    loadPdf() {
      if (process.browser) {
        this.createPdfTextField({
          fieldName: 'Date of Birth:',
          value: this.documentInfo.dob,
          spaceBetweenFieldname: 9,
        })
        this.currentXcoord += this.hspace

        this.createPdfTextField({
          fieldName: 'EMR#:',
          value: this.documentInfo.emr,
          spaceBetweenFieldname: 8,
        })
        this.currentXcoord += this.hspace

        this.createPdfTextField({
          fieldName: 'Lab:',
          value: this.documentInfo.lab,
          spaceBetweenFieldname: 5,
        })
        this.currentYcoord += this.vspace
        this.currentXcoord += this.hspace+5
        this.createPdfTextField({
          fieldName: 'Specimen Type:',
          value: 'Sperm',
          spaceBetweenFieldname: 10,
        })
        this.currentXcoord = 10 //reset

        this.createPdfTextField({
          fieldName: 'Freeze Dates(s):',
          value: 'All freeze dates and/or recieve dates prior to ' + moment().format('MM/DD/YYYY'),
          spaceBetweenFieldname: 13,
        })
        this.currentYcoord += this.vspace

        this.createPdfHeader({
          header: 'CHANGE OF DISPOSITION FORM: DISCARD SPERM',
          fillColor: '#f0f0f0',
        })

        this.createPdfText('IMPORTANT NOTICE', this.maxWidth / 2 - 8)
        this.currentYcoord += this.vspace

        this.createPdfText(
          'Below you will find a disposition option to discard your cryo preserved sperm which resulted from your medical treatments at a Prelude Network™ fertility center (“Prelude”). Please read the following Change of Disposition Form (the “Form”) carefully and make sure you understand the options governed by this Form. Please carefully read all of the important terms and conditions that follow in this Form. This Form represents a final, binding and irrevocable agreement between you and Prelude.'
        )

        this.currentYcoord += this.vspace * 4

        this.createPdfTextField({
          fieldName: 'Patient Name:',
          value: this.documentInfo.patientName,
          lineLength: 60,
        })
        this.createPdfTextField({
          fieldName: 'Date of Birth:',
          value: this.documentInfo.dob,
          lineLength: 60,
        })
        this.currentXcoord = 10 //reset coord x
        this.currentYcoord += this.vspace + 5

        this.createPdfTextField({
          fieldName: 'Address:',
          value: this.documentInfo.address,
          lineLength: 168,
        })
        this.currentYcoord += this.vspace * 2
        this.currentXcoord = 10

        this.createPdfText(
          'ALL SPERM IS STORED ACCORDING TO THE DATE ON WHICH THEY WERE FROZEN (“FREEZE DATE”). SPERM FROZEN ON THE SAME FREEZE DATE IS ALL STORED TOGETHER. ANY DISPOSITION RELATED TO A PARTICULAR FREEZE DATE WILL APPLY TO ALL SPERM STORED FOR THAT FREEZE DATE.'
        )
        this.currentYcoord += this.vspace * 2 + 2

        this.createPdfTextWithUnderline(
          'SPERM CAN BE DISCARDED EITHER IMMEDIATELY OR AFTER USE FOR LABORATORY TRAINING AND QUALITY ASSURANCE PURPOSES.',
          true
        )
        this.currentYcoord += this.vspace * 2

        this.createPdfText(
          'Any material you elect to donate to Prelude for laboratory training purposes and quality assurance may be used to improve Prelude’s laboratory techniques and Prelude’s embryologists’ skills. If you wish to donate your discarded sperm to Prelude for laboratory training and quality assurance, please sign the consent under the heading “DISCARD AFTER USE FOR TRAINING PURPOSES”. All materials used for this purpose would be de-identified. No materials would be used to establish a pregnancy. All sperm donated for this purpose which has not been used within 24 months will be discarded in accordance with Prelude’s policies. This selection will apply to all the freeze dates selected above. If you do not wish to donate your discarded sperm to Prelude for laboratory training and quality assurance, please sign under the heading “DISCARD ONLY”'
        )
        this.currentYcoord += this.vspace * 5.2

        this.createPdfHeader({ header: 'DISCARD ONLY', fontSize: 10 })

        this.createPdfText(
          'I would like Prelude to discard my sperm currently stored by Prelude under the Freeze Date(s) listed above, and I hereby order and direct Prelude and its physicians, employees and agents to destroy all such frozen sperm stored under such Freeze Date(s) without the use of such sperm for any purpose prior to destruction.  I understand as the result of such destruction no offspring will result from the sperm and I will have no further rights or claims to such sperm. I have freely, voluntarily and willingly made my decision regarding the disposal of my sperm and release Prelude from all claims of any nature arising from, or relating to, the sperm or the destruction thereof.'
        )
        this.currentYcoord += this.vspace * 4.5

        this.createPdfTextField({
          fieldName: 'Patient Signature:',
          lineLength: 60,
        })
        this.createPdfTextField({ fieldName: 'Printed Name:', lineLength: 50,  value: this.documentInfo.patientName, })
        this.currentXcoord = 10
        this.currentYcoord += this.vspace * 1.5

        this.createPdfTextField({ fieldName: 'Date:', lineLength: 78, value: moment().format('MM/DD/YYYY') })

        //Page 2
        this.doc.addPage('a4', 'p')
        this.currentYcoord = 10

        this.currentYcoord += this.vspace * 3

        this.createPdfHeader({
          header: 'DISCARD AFTER USE FOR TRAINING PURPOSES',
          fontSize: 10,
        })
        this.currentXcoord = 10

        this.createPdfText(
          'I would like to donate my discarded sperm to Prelude for laboratory training and quality assurance under the Freeze Date(s) listed above. I understand that all materials used for this purpose would be de-identified and no materials would be used to establish a pregnancy. Any material I have donated to Prelude for laboratory training purposes and quality assurance may be used to improve Prelude’s laboratory techniques and Prelude’s embryologists’ skills. I understand that all sperm donated for this purpose which has not been used within 24 months will be discarded in accordance with Prelude’s policies. This selection will apply to all the freeze dates selected above. I have freely, voluntarily and willingly made my decision regarding the donation for laboratory training and quality assurance of my sperm and release Prelude from all claims of any nature arising from, or relating to, the sperm or the use for laboratory training and quality assurance.'
        )

        this.currentYcoord += this.vspace * 6.5

        this.createPdfTextField({
          fieldName: 'Patient Signature:',
          lineLength: 60,
        })
        this.createPdfTextField({ fieldName: 'Printed Name:', lineLength: 50,  value: this.documentInfo.patientName, })
        this.currentXcoord = 10
        this.currentYcoord += this.vspace * 1.5

        this.createPdfTextField({ fieldName: 'Date:', lineLength: 78, value: moment().format('MM/DD/YYYY') })
        this.currentXcoord = 10
        this.currentYcoord += this.vspace * 2.5

        this.createPdfText(
          'Please sign on all signature lines below in addition to the disposition you have chosen from above.'
        )
        this.currentYcoord += this.vspace * 1.5

        this.createPdfTextWithUnderline('LEGAL STATUS OF SPERM')
        this.currentYcoord += this.vspace * 1.5

        this.createPdfText(
          'I understand that, as of the date of this Form, the legal rights of patients and their partners, or other parties, regarding the use and disposition of cryopreserved sperm in the circumstances described in this form have not been statutorily or otherwise legally determined. If any of the selected uses or dispositions outlined in this document are subsequently prohibited by law, then such selected uses or dispositions shall be deemed null and void. I acknowledge and agree that if Prelude becomes legally prohibited from exercising the discard options contemplated by this form, then Prelude will continue to store the sperm and I will remain obligated to pay for such storage.'
        )
        this.currentYcoord += this.vspace * 5

        this.createPdfTextField({
          fieldName: 'Patient Signature',
          lineLength: 90,
          lineOnTop: true,
        })
        this.currentYcoord += this.vspace * 2

        this.createPdfTextWithUnderline('INDEMNIFICATION')
        this.currentYcoord += this.vspace * 1.5

        this.createPdfText(
          "I acknowledge and agree that upon my signing of this form the disposition of my sperm will be final, binding and irrevocable.  I recognize that patients may change their thoughts regarding the disposition of cryopreserved sperm, which in turn may create a legal dispute. I understand that Prelude is entitled enforce the terms of this Form in such situations, subject to applicable law. I understand that Prelude is not willing to assume the risk for costs associated with such legal actions and I THEREFORE AGREE to indemnify and hold Prelude harmless against any loss, liability, cost, damage, or expense whatsoever (including attorney's fees and court costs) incident to any claim, action, or proceedings arising out of or in connection with this Form or my participation with the services provided by Prelude."
        )

        this.currentYcoord += this.vspace * 5.5
        this.createPdfTextField({
          fieldName: 'Patient Signature',
          lineLength: 90,
          lineOnTop: true,
        })

        this.currentYcoord += this.vspace * 1.5
        this.createPdfTextWithUnderline('RELIANCE ON FORM')

        this.currentYcoord += this.vspace
        this.createPdfText(
          'I understand and agree that Prelude shall rely upon this Form and decisions made by you in the Form. Prelude shall not be obligated to contact you prior to acting pursuant to the direction and authorization in the Form. Prelude shall have no duties except those which are expressly set forth in the Form, and its duties shall not be changed, unless Prelude has given its prior written consent thereto.'
        )

        this.currentYcoord += this.vspace * 3
        this.createPdfTextField({
          fieldName: 'Patient Signature',
          lineLength: 90,
          lineOnTop: true,
        })

        //Page 3
        this.doc.addPage('a4', 'p')
        this.currentYcoord = 10

        this.currentYcoord += this.vspace * 1.5
        this.createPdfTextWithUnderline('OPPORTUNITY FOR LEGAL REPRESENTATION')

        this.currentYcoord += this.vspace
        this.createPdfText(
          'I acknowledge by my signatures below that I have read the foregoing and that all questions have been answered to my  satisfaction and that we have each been advised to, and have had the opportunity to, consult with legal counsel of my own choosing.'
        )

        this.currentYcoord += this.vspace * 2.5
        this.createPdfTextField({
          fieldName: 'Patient Signature',
          lineLength: 90,
          lineOnTop: true,
        })

        this.currentXcoord = 10
        this.currentYcoord += this.vspace * 2.5
        this.createPdfTextWithUnderline('CERTIFICATE OF ACKNOWLEDGEMENT')

        this.currentYcoord += this.vspace * 2
        this.createPdfTextField({ fieldName: 'State of', lineLength: 50 })
        this.currentYcoord += this.vspace * 1.5
        this.currentXcoord = 10
        this.createPdfTextField({ fieldName: 'County of', lineLength: 50 })

        this.currentXcoord = 10
        this.currentYcoord += this.vspace * 2
        this.createPdfTextField({ fieldName: 'On', lineLength: 80 })
        this.currentXcoord = 98
        this.createPdfTextField({ fieldName: 'before me,', lineLength: 70 })
        this.currentXcoord = 10
        this.currentYcoord += this.vspace
        this.doc.text('(date)', this.currentXcoord + 10, this.currentYcoord)
        this.doc.text(
          '(notary name)',
          this.currentXcoord + 110,
          this.currentYcoord
        )

        this.currentYcoord += this.vspace * 2
        this.createPdfText('personally, appeared, (signers)')

        this.currentYcoord += this.vspace
        this.createPdfTextField({
          fieldName: 'Printed patient name:',
          lineLength: 70,
        })

        this.currentXcoord = 10
        this.currentYcoord += this.vspace
        this.createPdfText('personally, known to me -OR-')

        this.currentYcoord += this.vspace
        this.createPdfText(
          'proved to me on the basis of satisfactory evidence to be the person(s) whose name(s) is/are subscribed to the within instrument and acknowledged to me that he/she/they executed the same in his/her/their authorized capacity(ies), and that by his/her/their signature(s) on the instrument the person(s) or the entity upon behalf of which the person(s) acted, executed the instrument'
        )

        this.currentYcoord += this.vspace * 2.5
        this.createPdfText(
          'WITNESS my hand and official seal',
          this.currentXcoord + 100
        )

        this.currentYcoord += this.vspace * 3.5
        this.createPdfTextField({
          fieldName: '(seal)',
          lineLength: 80,
          lineOnTop: true,
        })

        this.currentXcoord = 10
        this.currentYcoord += this.vspace * 2
        this.createPdfTextField({
          fieldName: 'Reviewer Signature: ',
          lineLength: 80,
        })
        this.currentXcoord = 10
        this.currentYcoord += this.vspace
        this.doc.text(
          '(Centralized Cryo Services Signature)',
          this.currentXcoord + 35,
          this.currentYcoord
        )
      }
    },
  },
}
</script>