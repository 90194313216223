import { AcroFormCheckBox } from 'jspdf';

export default {
  data: () => ({
    doc: null,
    currentXcoord: 10,
    currentYcoord: 10,
    hspace: 45,
    vspace: 7,
    maxWidth: 190,
    font: 'times'
  }),
  methods: {
    createPdfTextField({ fieldName, value = '', spaceBetweenFieldname = 20, lineLength, lineOnTop }) {
      this.doc.setFontSize(10);
      this.doc.text(fieldName, this.currentXcoord, this.currentYcoord);

      this.doc.setTextColor('gray');
      this.doc.setFontSize(12);
      this.doc.setFont(this.font, 'normal');
      this.doc.text(value, this.currentXcoord + fieldName.length + spaceBetweenFieldname, this.currentYcoord, { maxWidth: this.maxWidth });

      if (lineLength && !lineOnTop) {
        const width = this.doc.getStringUnitWidth(fieldName) * 10 / 2.8 + 1;
        this.currentXcoord += width;
        this.doc.line(this.currentXcoord, this.currentYcoord + 1, this.currentXcoord + lineLength, this.currentYcoord + 1);
        this.currentXcoord += lineLength + 20;
      }

      if (lineLength && lineOnTop) {
        this.doc.line(this.currentXcoord, this.currentYcoord - 10 / 2.8, this.currentXcoord + lineLength, this.currentYcoord - 10 / 2.8);
      }

      this.doc.setTextColor('black')
      this.doc.setFontSize(10);
      this.doc.setFont(this.font, 'normal');
    },
    createPdfHeader({ header, fontSize = 13, fillColor = '#ddd9c3' }) {
      this.doc.setFillColor(fillColor);
      this.doc.rect(9, this.currentYcoord, this.maxWidth, 10, 'F');
      this.doc.setFontSize(fontSize);
      this.doc.text(header, 105, this.currentYcoord + 7, { maxWidth: this.maxWidth, align: 'center' });
      this.currentYcoord += this.vspace + 10;
      this.doc.setFontSize(10);
    },
    createPdfTextWithUnderline(text, centered = false) {
      if (centered) {
        const startingX = this.currentXcoord + 90;
        const textArray = this.doc.splitTextToSize(text, this.maxWidth - 10);

        this.doc.text(text, startingX, this.currentYcoord, { maxWidth: this.maxWidth - 10, align: 'center' });

        let lineY = this.currentYcoord + .5;
        textArray.forEach(line => {
          let lineWidth = this.doc.getStringUnitWidth(line) * 10 / 2.8;
          let lineX = 100 - ((lineWidth - 1) / 2);

          this.doc.line(lineX, lineY, lineWidth + lineX, lineY);
          lineY = lineY + 4;
        });
      } else {
        const width = this.doc.getStringUnitWidth(text) * 10 / 2.8;

        this.doc.text(text, this.currentXcoord, this.currentYcoord);
        this.doc.line(this.currentXcoord, this.currentYcoord + .5, this.currentXcoord + width, this.currentYcoord + .5);
      }
    },
    createPdfCheckboxWithText(fieldName, text) {
      let startingX = this.currentXcoord;
      this.doc.setFontSize(9);
      this.doc.text(`${fieldName}:`, startingX, this.currentYcoord);

      startingX += 12;
      const checkBox = new AcroFormCheckBox();
      checkBox.fieldName = "CheckBox1";
      checkBox.appearanceState = 'Off';
      checkBox.Rect = [startingX, this.currentYcoord - 4, 5, 5];
      this.doc.addField(checkBox);

      startingX += 7;
      this.doc.line(startingX, this.currentYcoord, startingX + 7, this.currentYcoord);

      startingX += 9;
      this.doc.setFontSize(10);
      this.doc.text(text, startingX, this.currentYcoord);
    },
    createPdfCheckboxFieldsWithText(fieldNames, text) {
      let startingX = this.currentXcoord;
      this.doc.setFontSize(9);

      fieldNames.forEach(field => {
        this.doc.text(field, startingX, this.currentYcoord);
        startingX += 12;
        const checkBox = new AcroFormCheckBox();
        checkBox.fieldName = "CheckBox1";
        checkBox.appearanceState = 'Off';
        checkBox.Rect = [startingX, this.currentYcoord - 4, 5, 5];
        this.doc.addField(checkBox);
        startingX += 7;
        this.doc.line(startingX, this.currentYcoord, startingX + 7, this.currentYcoord);
        startingX += 9;
      });

      this.doc.setFontSize(10);
      this.doc.text(text, startingX, this.currentYcoord);
    },
    addDraftStamp() {
      this.doc.setTextColor('gray');
      this.doc.text('DRAFT', 190, 15);
      this.doc.setTextColor('black');
    },
    createPdfText(text, xCoord) {
      const x = xCoord ?? this.currentXcoord;

      this.doc.text(text, x, this.currentYcoord, { maxWidth: this.maxWidth, lineHeightFactor: 1.3 });
    }
  }
}