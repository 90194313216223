<template>
<v-container>
 <v-card flat style="padding: 20px;">
     <v-card-title class="justify-center">{{this.header1}}</v-card-title>
        <v-divider></v-divider>
        <v-card-subtitle class="text-center">{{ this.header2 }}</v-card-subtitle>
        <v-row>
        <v-col cols='5'>
           
        <h2>Current Cryo Balance: {{info[0].Balance}}</h2>
        
        <h3>Current Status: {{info[0].status}}</h3>
        
        <h3>Due Date:{{info[0].duedate}}</h3>
         
        <v-row class="pt-4 pl-16 pr-16">
         <v-btn 
          dark
          color="primary"
          rounded 
          block
          large
          @click="overlay = !overlay"
        >
          Make a Payment
        </v-btn>
        <v-overlay :value=overlay>
          <Payment @closeDialog="overlay = !overlay" :patient="patient" :cards="cards" :reloadCards="isEnrolled" v-on="$listeners"/>
        </v-overlay>
        
        
        </v-row>
        <v-row class="pt-2 pl-16 pr-16">
         <v-btn 
          dark
          color="primary"
          rounded 
          block
          large
          @click="overlay = !overlay"
        >
          Enroll in Auto Pay
        </v-btn>
        </v-row>
        <v-row  class="pt-2 pl-16 pr-16">
        <v-btn 
          dark
          color="primary"
          rounded 
          block
          large
          @click="dialog = !dialog"
        >
          Manage Payment Methods
          <v-icon right>mdi-set-none</v-icon>
        </v-btn>
        <v-dialog width="50%" scrollable v-model="dialog">
          <Manage @goBack="dialog = !dialog" :cards="cards" v-on="$listeners" />
        </v-dialog>
        </v-row>
        </v-col>
        <v-col cols='6'>
            <h2 class="justify-center">My Tissues</h2>
            <v-tabs
      v-model="tab"
      background-color="primary"
      color="basil"
      grow
    >
      <v-tab
        href="#tab1"
      >
        Embryos
      </v-tab>
      <v-tab
        :key="2"
      >
        Eggs
      </v-tab>
      <v-tab
        :key="3"
      >
        Sperm
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
        <v-tab-item
        value="tab1"
      >
        <v-card
        >
          <v-card-text>
              
               <v-data-table
              :headers="freezeDateHeaders"
              :items="freezeDates"
              :loading="loadingFreezeDates"
              :search="search1"
            >
              <template v-slot:[`item.date`]="{ item }">
                {{ item.date }}   
              </template>
              <template v-slot:[`item.location`]="{ item }">
                {{ item.location }}   
              </template>
               <template v-slot:[`item.type`]="{ item }">
                {{ item.type }}   
              </template>
               <template v-slot:[`item.amount`]="{ item }">
                ${{ item.amount || 0}}   
              </template>
               <template v-slot:[`item.method`]="{ item }">
                {{ item.method }}   
              </template>
            </v-data-table>
           <v-btn    
              dark
              color="primary"
              rounded 
              block
              large
              :disabled="existingDispositions.embryo"
              @click="() => $refs.embryoDisposition.show()" 
            >
              {{ existingDispositions.embryo ? 'Discard request sent to lab' : 'Discontinue Embryo Storage' }}
            </v-btn>
            <Disposition ref="embryoDisposition" tissuetype="Embryo" @disposition-submitted="getDispositions" :url="url" />
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item
        :key="2"
      >
        <v-card
          color="basil"
          flat
        >
          <v-card-text>
               <v-data-table
              :headers="freezeDateHeaders"
              :items="freezeDates"
              :loading="loadingFreezeDates"
              :search="search2"
            >
              <template v-slot:[`item.date`]="{ item }">
                {{ item.date }}   
              </template>
              <template v-slot:[`item.location`]="{ item }">
                {{ item.location }}   
              </template>
               <template v-slot:[`item.type`]="{ item }">
                {{ item.type }}   
              </template>
               <template v-slot:[`item.amount`]="{ item }">
                ${{ item.amount || 0}}   
              </template>
               <template v-slot:[`item.method`]="{ item }">
                {{ item.method }}   
              </template>
            </v-data-table>
             <v-btn    
              dark
              color="primary"
              rounded 
              block
              large
              :disabled="existingDispositions.egg"
              @click="() => $refs.eggDisposition.show()" 
            >
              {{ existingDispositions.egg ? 'Discard request sent to lab' : 'Discontinue Egg Storage' }}
            </v-btn>
            <Disposition ref="eggDisposition" tissuetype="Egg" @disposition-submitted="getDispositions" :url="url" />
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item
        :key="3"
      >
        <v-card
          color="basil"
          flat
        >
          <v-card-text>
               <v-data-table
              :headers="freezeDateHeaders"
              :items="freezeDates"
              :loading="loadingFreezeDates"
              :search="search3"
            >
              <template v-slot:[`item.date`]="{ item }">
                {{ item.date }}   
              </template>
              <template v-slot:[`item.location`]="{ item }">
                {{ item.location }}   
              </template>
               <template v-slot:[`item.type`]="{ item }">
                {{ item.type }}   
              </template>
               <template v-slot:[`item.amount`]="{ item }">
                ${{ item.amount || 0}}   
              </template>
               <template v-slot:[`item.method`]="{ item }">
                {{ item.method }}   
              </template>
            </v-data-table>
             <v-btn    
              dark
              color="primary"
              rounded 
              block
              large
              :disabled="existingDispositions.sperm"
              @click="() => $refs.spermDisposition.show()" 
            >
              {{ existingDispositions.sperm ? 'Discard request sent to lab' : 'Discontinue Sperm Storage' }}
            </v-btn>
            <Disposition ref="spermDisposition" tissuetype="Sperm" @disposition-submitted="getDispositions" :url="url" />
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
        </v-col>
        </v-row>
       
        <v-row>
            <h2>My Invoices</h2>
            
        </v-row>
        <v-row>
             <v-col cols='12'>
        <MakePayment :patient="patient" :url="url" :key="patient.id || 0" />
             </v-col>
        </v-row>
        <v-row>
            <h2>Past Transactions</h2>
        </v-row>
        <v-row>
            <v-col cols='12'>
            <v-data-table
              :headers="transactionHeaders"
              :items="transactions"
              :loading="loadingTransactions"
            >
              <template v-slot:[`item.date`]="{ item }">
                {{ item.date }}   
              </template>
              <template v-slot:[`item.amount`]="{ item }">
                {{ item.amount }}   
              </template>
              <template v-slot:[`item.cardType`]="{ item }">
                {{ item.cardType }}   
              </template>
              <template v-slot:[`item.invoice`]="{ item }">
                {{ item.invoice }}   
              </template>
              <template v-slot:[`item.notes`]="{ item }">
                {{ item.note }}   
              </template>
            </v-data-table>
            </v-col>
        </v-row>
       
 </v-card>
</v-container>
</template>
<script>
import axios from 'axios'
import {auth} from '../sharedPlugin'
import {api} from '../sharedPlugin'
import Manage from '../components/cryo/Manage.vue'
import Disposition from '../components/cryo/Disposition.vue'
import CardInfo from '../components/cryo/CardInfo.vue'
import MakePayment from '../components/cryo/MakePayment2.vue'
import CardForm from '../components/cryo/CardForm.vue'
import Payment from '../components/cryo/Payment'

export default {
  components: { Disposition,  Manage, MakePayment, Payment}, 
  data () {
    return {
      tab: null,
      dispositionFreezeDate: null,
      loadingTransactions: false,
      loadingFreezeDates: false,
      loadingNextPayment: false,
      overlay: false,
      cards: [],
      message: '',
      showSnackBar: false,
      color: '',
      dialog: false,
      dialog2: false,
      info: [],
      search1: 'Embryo',
      search2: 'Egg',
      search3: 'Sperm',
      get userident() {
        return JSON.parse(localStorage.getItem('auth')) || 0;
      },
      freezeDateHeaders: [
        {text: "Freeze Date", value: "date", sortable: true},
        {text: "Location", value: "location", sortable: true},
        {text: "Type", value: "type", sortable: true},
        {text: "Amount", value: "amount", sortable: true},
        {text: "Reoccurance", value: "method", sortable: true},
      ],
      transactionHeaders: [
        {text: "Date", value: "date", sortable: true},
        {text: "Amount", value: "amount", sortable: true},
        {text: "Payment Method", value: "cardType", sortable: true},
        {text: "Invoice#", value: "invoice", sortable: true},
        {text: "Description", value: "notes", sortable: true},
      ],
      freezeDates: [],
      transactions: [],
      nextPayment: null,
      balance: 0.00,
      header1: "Welcome to the Prelude Connect Cryopreservation Portal",
      header2: "Thank you for choosing a Prelude Fertility clinic for your reproductive health.  We are committed to the safe and secure storage of your precious cryopreserved eggs, embryos and sperm.   In this portal you can view the details of your stored reproductive tissue, securely pay for your storage fees or complete a consent to discontinue storage.",
      existingDispositions: {
        'embryo': false,
        'sperm': false,
        'egg': false
      },
      url: 'https://apiv1.inceptionllc.com',
      patient: null
    }
  },
  computed: {
    nextPaymentString() {
      return this.nextPayment ? "Next Payment: " + this.nextPayment.date + " " + this.nextPayment.amount : ''
    },
    ...auth.mapComputed(['user'])
  },
  methods: {
    async isEnrolled(){  
      this.loadingEnrolled = true;

      await api.CardConnect.getCardConnectProfile().then(async (res) => {
        if(res.length > 0) {
          const cardProfiles = [...res]

          await cardProfiles.forEach(async cardProfile => {
            await api.CardConnect.getCardInfo({profile: cardProfile.profileid, account: cardProfile.accountid})
              .then(res => {
                const {expiry} = res;
                console.table(res)

                this.cards.push({
                  ...cardProfile,
                  account: res.account,
                  cardType: cardProfile.description,
                  expiry: expiry ?? '',
                })
              })
              .catch(err => {
                console.log(err)
              })
          })
        } 
      }).catch(err => alert(err)).finally(() => this.loadingEnrolled = false)
    },
    async addorUpdateCard(card, config) {
      this.loadingEnrolled = true;

      if(card.profileid !== 0){
        // update
        api.CardConnect.updateCard(card)
          .then(res =>{
            this.updateCard(card)
            this.loadingEnrolled = false
          })
          .catch(err => console.error(err))
        await this.isEnrolled()
      }
      else{
        // add
        api.CardConnect.addCard(card)
          .then(res => {
            this.cards.push({
              ...res,
              cardType: res.description,
              expiry: card.expiry
            });
            this.loadingEnrolled = false
            this.showMessage({ message: "Card Added!", color: 'green' });
          })
          .catch(err => console.error(err))
      }
    },
    async updateCard(card) {
      const index = this.cards.findIndex(c => c.id === card.id);

      if(index !== -1){
        this.cards.splice(index, 1, card);
        this.showMessage({ message: "Updated Card!", color: "green" });
      } else {
        this.showMessage({ message: "Error updating card", color: "red" });
      }
      await this.isEnrolled()
    },
    showMessage({message, color}) {
      this.color = color;
      this.message = message;
      this.showSnackBar = true;
    },
    async getPatientInfo() {
      return await axios
        .post(`${this.url}/api/v1/inova/patients/getConnectPatient`, {
          connectId: this.user.userId
        }, {
          headers: {
            'x-api-key': '58b54801044df5a0536c25db11c653c743761b2db21e044aedb9f91caa6138a5'
          },
        })
        .then((response) => {
          return response.data[0]
        })
        .catch((err) => {
          console.error(err)
        })
    },
    async getInfo(){
      await axios
        .post(`${this.url}/api/v1/inova/cardConnect/accountinfo`, {
          id: this.patient.id
        }, {
          headers: {
            'x-api-key': '58b54801044df5a0536c25db11c653c743761b2db21e044aedb9f91caa6138a5'
          },
        })
        .then(response => {
          this.info = response.data
        })
        .catch((err) => {
          console.error(err)
        })

    },
    async getDispositions() {
      await axios
        .post(`${this.url}/api/v1/inova/dispositions/getCurrent`, {
          pid: this.patient.id
        }, {
          headers: {
            'x-api-key': '58b54801044df5a0536c25db11c653c743761b2db21e044aedb9f91caa6138a5'
          },
        })
        .then(response => {
          for (const disposition of response.data) {
            if (disposition.type) {
              this.existingDispositions[disposition.type.toLowerCase()] = true
            }
          }
        })
        .catch((err) => {
          console.error(err)
        })
    },
    getDate(date){
      date = date.split("T")[0]
      date = date.split("-")
      var month = date[1]
      var day = date[2]
      var year = date[0]

      return `${month}/${day}/${year}`
    },
    getFreezeDates() {
      this.loadingFreezeDates = true
      api.Cryo.getFreezeDates()
        .then(res => {
          this.freezeDates = res.map(freezeDate => ({
            date: this.getDate(freezeDate.InventoryDate),
            location: freezeDate.location,
            type: freezeDate.type,
            amount: freezeDate.amount,
            method: freezeDate.method
          }))
        })
        .finally(() => this.loadingFreezeDates = false)
    },
    checkVersion(){
      var url = window.location.href
      if(url.includes("havencryo")){
        this.header1 = 'Welcome to HavenCryo'
        this.header2 = "Thank you for choosing HavenCryo.  We are committed to the safe and secure storage of your precious cryopreserved eggs, embryos and sperm.   In this portal you can view the details of your stored reproductive tissue, securely pay for your storage fees or complete a consent to discontinue storage."
      }
      if(url.includes("localhost")){
        this.header1 = 'Welcome to HavenCryo'
        this.header2 = "Thank you for choosing HavenCryo.  We are committed to the safe and secure storage of your precious cryopreserved eggs, embryos and sperm.   In this portal you can view the details of your stored reproductive tissue, securely pay for your storage fees or complete a consent to discontinue storage."
      }
    },
    getTransactions() {
      this.loadingTransactions = true
      api.CardConnect.getTransactions()
        .then(res => {
          this.transactions = res.map(transaction => ({
            date: this.getDate(transaction.datetime),
            amount: `$${transaction.amount}`,
            cardType: transaction.type,
            invoice: transaction.invoice,
            note: transaction.notes
            
          }))
        })
        .finally(() => this.loadingTransactions = false)
    },
    getNextPayment() {
      this.loadingNextPayment = true
      api.CardConnect.getRecurringPayments()
        .then(res => {
          if(res.length > 0){
            this.nextPayment = res.map(payment => ({
              date: this.getDate(payment.payment_date),
              amount: payment.amount
            }))[0]
          }
        })
        .finally(() => this.loadingNextPayment = false)
    },
  },
  async mounted() {
    this.checkVersion()
    this.patient = await this.getPatientInfo()
    await this.getDispositions()
    await this.getFreezeDates()
    await this.getTransactions()
    await this.getNextPayment()
    await this.getInfo()
  },
  async created(){
    await this.isEnrolled()
    console.log(this.cards)
  }
}
</script>

<style scoped>
  .theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
    background-color: rgba(0, 0, 0, 0.3) !important;
    color: #fff !important;
  }
</style>