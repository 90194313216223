<template>
  <div>
    <v-card v-if="this.patient && !isFuturePayment" flat>
      <v-data-table
        :key="tableKey"
        :loading="loading"
        :headers="headers"
        :items="items"
        :search="search"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
      >
        <template #item="{ item }">
          <tr :class="[item.status === 'PAST DUE' ? 'red lighten-5' : '']">
            <td>{{ item.id }}</td>
            <td>{{ item.description }}</td>
            <td>{{ formatter.format(item.total) }}</td>
            <td>{{ formatter.format(item.paid) }}</td>
            <td>{{ item.status }}</td>
            <td>{{ item.duedate }}</td>
            <td>
              <v-btn
                v-if="item.status === 'OPEN' || item.status === 'PAST DUE'"
                @click="payNow(item)"
                color="#3a5c77 white--text"
                rounded
              >
                Pay Now
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
    <div v-else>
      <CardForm 
        :invoice="invoice" 
        :invamount="invamount" 
        :url="url" 
        :patient="patient" 
        @dialog-close="() => isFuturePayment = false"
      />
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import CardForm from './CardForm2.vue'

export default {
  props: ['url', 'patient'],
  components: {
    CardForm
  },
  data() {
    return {
      loading: true,
      active: true,
      search: '',
      items: [],
      invoice: 0,
      invamount: 0,
      sortBy: 'status',
      sortDesc: true,
      tableKey: 0,
      headers: [
        { text: 'Invoice#', align: 'start', value: 'id' },
        { text: 'Details', value: 'description' },
        { text: 'Invoiced Amount', value: 'total' },
        { text: 'Paid', value: 'paid' },
        { text: 'Status', value: 'status' },
        { text: 'Due Date', value: 'duedate' },
        { text: 'Options', value: 'options' },
      ],
      formatter: new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }),
      isFuturePayment: false
    }
  },
  async mounted() {
    await this.getInvoices()
  },
  methods: {
    payNow(item) {
      const { id, total, paid } = item    

      this.invoice = id
      this.isFuturePayment = true
      this.invamount = total - paid
    },
    async getInvoices() {
      await axios
        .post(`${this.url}/api/v1/inova/cryoInventory/connectInvoices`, {
          id: this.patient.id,
        }, {
          headers: {
            'x-api-key': '58b54801044df5a0536c25db11c653c743761b2db21e044aedb9f91caa6138a5'
          },
        })
        .then(response => {
          this.items = response.data
          var tot = 0.00
          for (var i = 0; i < this.items.length; i++) {  //loop through the array
            tot += this.items[i].total; //Do the math!
          }
          this.$emit('balance',tot);
          this.loading = false
        })
    },
  },
}
</script>
